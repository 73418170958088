.slider-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.slick-dots {
  bottom: 10px;
}

.slick-slide img {
  display: block;
  width: 100%;
  height: auto;
}

.slide {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
}

.slide img {
  max-width: 100%;
  height: 100%;
  /*background-size: auto auto;*/
  object-fit: cover;
}

.slide-content {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 20px;
  background: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
}

.slide-description {
  color: white;
  text-align: left;
  margin: 25px;
  width: 50%;
}

.slide-description p {
  margin: 0;
  font-size: 20px;
  font-family: Arial, sans-serif;
}

.slide-description a {
  display: inline-block;
  margin-top: 10px;
  color: #38b6ff;
  text-decoration: none;
  font-weight: bold;
  border: 1px solid #38b6ff;
  border-radius: 10px;
  padding: 10px;
}

.prev-button, .next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0);
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 2;
}

.prev-button {
  left: 10px;
}

.next-button {
  right: 10px;
}

.prev-button svg, .next-button svg {
  font-size: 24px;
  color: #38b6ff;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .slide {
    height: 300px;
  }

  .slide-content {
    width: 100%;
    padding: 10px;
    background: rgba(0, 0, 0, 0.5);
    justify-content: flex-start;
  }

  .slide-description p {
    font-size: 16px;
  }

  .slide-description a {
    font-size: 14px;
  }

  .prev-button, .next-button {
    padding: 5px;
  }

  .prev-button svg, .next-button svg {
    font-size:18px;
  }
}

@media (max-width: 480px) {
  .slide {
    height: 280px;
  }

  .slide-content {
    width: 100%;
    padding: 8px;
    background: rgba(0, 0, 0, 0.5);
    justify-content: flex-start;
  }

  .slide-description{
    width: 100%;
  }

  .slide-description p {
    font-size: 14px;
  }

  .slide-description a {
    font-size: 14px;
  }

  .prev-button, .next-button {
    padding: 5px;
  }

  .prev-button svg, .next-button svg {
    font-size: 16px;
  }
}
