/*HEADER PLANES*/
.planes-header {
    text-align: center;
    margin: 40px 20px;
}

.planes-header h4 {
    text-align: center;
    font-size: 2rem;
    color: white;
    margin-bottom: 10px;
    border-radius: 30px;
    background-color: #38b6ff94;
    padding: 15px;
    width: 70%;
    margin: 0 auto;
    margin-bottom: 15px;
}

.planes-header p {
    font-size: 1.125rem;
    color: #555;
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.6;
}


/*card planes*/
.home-planes {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    padding: 20px;
    top: 15px;
}

.card-home {
    background-color: #f8f8f8;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    transition: transform 0.3s ease-in-out, max-height 0.5s ease-in-out;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 240px;
}

.descripcion-card {
    font-size: 18px;
    text-align: left;
    position: absolute;
    bottom: 80px;
    right: 10px;
    margin-left: 20px;
}

.card-home button {
    background-color: #38b6ff;
    border: none;
    color: white;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 14px;
    margin-top: auto;
    width: 100px;
    margin-left: auto;
    margin-right: auto;
}

.card-home button:hover {
    background-color: #ff914d;
}

.all-planes {
    display: flex;
    justify-content: center;
    align-items: center;
}

.plan-button {
    background-color: white;
    color: #38b6ff;
    padding: 10px 20px;
    font-size: 16px;
    border: 1px solid #38b6ff;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.plan-button:hover {
    background-color: #38b6ff;
    color: white;
}


/* Home.css */
.home-container {
    margin: 25px 15px;
    padding: 20px;
    border-radius: 20px;
    text-align: center;
    background-color: #38b6ffbb;
    border: 1px solid #38b6ff;
    color: white;
}

.home-title {
    font-size: 2rem;
    margin-bottom: 20px;
}

/* Contenedor principal con Flexbox */
.content-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

/* Estilo para cada item */
.content-item {
    flex: 1 1 calc(50% - 40px);
    box-sizing: border-box;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #38b6ff;
    border: 3px solid #38b6ff;
    text-align: left;
    text-align: justify;
    font-size: 18px;
}

.content-item-ultimo {
    flex: 1 1 100%;
    background-color: #38b6ff;
    border: 1px solid #38b6ff;
    border-radius: 8px;
    font-size: 18px;
}

.content-item-ultimo p {
    text-align: justify;
    padding: 0px 20px;
    font-size: 18px;
}

/* Estilo para el último contenedor, centrado en la fila de escritorio */
.content-item:nth-child(5) {
    flex: 1 1 100%;
}



@media (max-width: 938px) {
    .planes-header h4 {
        font-size: 1rem;
        width: 90%;
    }
    .card-home {
        height: 300px;
    }
    .home-planes {
        grid-template-columns: repeat(3, 1fr);
    }
}



/* Estilos en pantallas pequeñas (móviles) */
@media (max-width: 768px) {

    /* Cards en columna para móvil */
    .home-planes {
        grid-template-columns: 1fr;
        gap: 15px;
    }

    .planes-header h4 {
        font-size: 1rem;
        width: 90%;
    }

    /* Ajustar padding para móvil */
    .card-home {
        padding: 15px;
        height: 200px;
    }

    .card-home p {
        font-size: 16px;
    }

    /* Botones más pequeños en móvil */
    .card-home button {
        padding: 8px 12px;
        font-size: 16px;
    }

    .content-item {
        flex: 1 1 100%;
        /* Ocupa el 100% en dispositivos móviles */
    }

    .plan-button {
        font-size: 16px;
    }

    .content-item {
        font-size: 16px;
    }

    .content-item-ultimo {
        text-align: left;
        font-size: 16px;
        text-align: justify;
    }

    .content-item-ultimo h2 {
        padding-left: 20px;
        padding-top: 20px;
    }

    .content-item-ultimo p {
        font-size: 16px;
    }
}