.logo-container {
    /*flex-grow: 1;
    display: flex;*/
    width: 80px;
    justify-content: center;
}

.logo {
    max-width: 100%;
    height: auto;
}

.nav-menu {
    display: flex;
    justify-content: center;
    flex-grow: 2;
}

.nav-menu .nav-item {
    color: white;
    background-color: #38b6ff;
    margin-right: 10px;
    text-decoration: none;
    padding: 10px;
    border: 1px solid transparent;
}

.nav-menu .nav-item:hover {
    background-color: #ff914d;
    color: white;
}

.nav-auth {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
}

.profile-button {
    background-color: #ff914d;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 16px;
    font-family: Arial, sans-serif;
    padding: 12px;
    border-radius: 5px;
}
.profile-button:hover {
    background-color: #38b6ff;
}

.dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    border: 1px solid #d3d4d5;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    z-index: 1000;
    min-width: 150px;
}

.dropdown-menu.show {
    display: block;
}

.dropdown-menu a,
.dropdown-menu button {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    color: #333;
    text-decoration: none;
    border: none;
    background: none;
    width: 100%;
    text-align: left;
    font-family: Arial, sans-serif;
    font-size: 14px;
    cursor: pointer;
    box-sizing: border-box;
}

.dropdown-menu a:hover,
.dropdown-menu button:hover {
    background-color: #f0f0f0;
}

.logout-icon {
    margin-right: 8px;
    display: flex;
    align-items: center;
    font-size: 18px;
}

.nav-auth .nav-item {
    padding: 10px;
    color: #333;
    background: none;
    border: none;
    text-align: right;
    cursor: pointer;
}

.nav-auth .login-btn {
    color: #38b6ff;
    border: 1px solid #38b6ff;
}

.nav-auth .login-btn:hover {
    color: white;
    background-color: #38b6ff;
}

.nav-auth .register-btn {
    color: white;
    background-color: #ff914d;
    box-shadow: none;
}

.nav-auth .register-btn:hover {
    color: #ff914d;
    border: 1px solid #ff914d;
    box-shadow: none;
}

.drawer {
    width: 250px;
}

.drawer-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
}

.drawer-close {
    align-self: flex-end;
    color: #38b6ff;
}

.drawer-item {
    color: #333;
}

.drawer-item:hover {
    background-color: #f0f0f0;
}


/* Estilos para el menú en dispositivos móviles y tabletas */
/*max-width: 768px*/
@media (max-width: 938px) {
    .menu-toggle {
        display: block;
    }

    .nav-menu {
        display: none;
    }

    .nav-auth {
        display: none;
    }

    .logo-container {
        flex-grow: 1;
        display: flex;
        justify-content: center;
    }
}

/*min-width: 769px*/
@media (min-width: 939px) {
    .menu-toggle {
        display: none;
    }

    .nav-menu {
        display: flex;
    }

    .nav-auth {
        display: flex;
    }
}