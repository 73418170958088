/* ModalLogin.css */

.modal-box {
  position: relative;
  top: 100px;
  margin-top: 100px;
  max-width: 500px; /* Ajusta según sea necesario */
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin: auto;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
}

.modal-close-button {
  position: relative;
  align-self: flex-end;
  right: 0px;
  color: #333;
}

.modal-title {
  margin-bottom: 20px;
  text-align: center;
}

.modal-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.modal-forgot-password {
  margin: 10px;
  top: 10px;
  cursor: pointer;
}

.modal-google-button {
  margin-top: 20px;
  top: 10px;
}

/* Estilos para dispositivos móviles */
@media (max-width: 600px) {
  .modal-box {
    margin: 10px;
  }
}
