.contact-form {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-form .MuiTextField-root {
    margin-bottom: 16px; 
}

.contact-form .MuiButton-root {
    margin-top: 20px; 
}