.footer {
  background-color: #ff914d;
  color: #fff;
  text-align: center;
  margin-top: 20px;
  padding: 20px 0;
  position: relative;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #ff914d;
}

.footer .social-icons a {
  color: white;
  margin: 0 10px;
  font-size: 24px;
  text-decoration: none;
}
.footer .social-icons a:hover {
  color: #38b6ff;
}

.footer p {
  margin-top: 10px;
}
.footer a{
  text-decoration: none;
  color: white;
}
.footer a:hover{
  text-decoration: none;
  color: #38b6ff;
}