.preguntas-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.pregunta-bubble {
    background-color: #38b6ff;
    color: white;
    padding: 15px 20px;
    border-radius: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    font-size: 16px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    margin: 10px;
    transition: transform 0.3s ease;
}

.pregunta-bubble.active {
    transform: scale(1.05);
}

.pregunta-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pregunta-title {
    margin: 0;
    font-size: 1.2rem;
}

.toggle-icon {
    font-size: 1.5rem;
    cursor: pointer;
    display: none;
    color: white;
}

.pregunta-description {
    margin: 10px 0 0;
    overflow: hidden;
    text-align: left;
    transition: max-height 0.3s ease;
}

.pregunta-bubble.active .pregunta-description {
    max-height: 300px;
}

@media (max-width: 938px) {
    .toggle-icon {
        display: inline;
    }
    .pregunta-description {
        margin: 10px 0 0;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease;
    }
}

@media (max-width: 768px) {
    .toggle-icon {
        display: inline;
    }
    .pregunta-description {
        max-height: 0;
    }
}