.conocenos-header {
    text-align: justify;
    padding: 20px;
    width: 60%;
    margin: 0 auto;
}

.conocenos-header h3 {
    text-align: center;
    font-size: 2rem;
    color: white;
    margin-bottom: 10px;
    border-radius: 30px;
    background-color: #38b6ff94;
    padding: 15px;
}

.conocenos-header p {
    color: #333;
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 15px;
}

.conocenos-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 20px;
    width: 60%;
    margin: 0 auto;
}

.card-conocenos {
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    transition: transform 0.3s ease;
}

.card-conocenos:first-child {
    margin-left: 0;
    margin-right: auto;
}

.card-conocenos:nth-child(2) {
    margin-left: auto;
    margin-right: 0;
}

.card-conocenos:hover {
    transform: scale(1.05);
}

.card-contenido {
    padding: 20px;
    background-color: #f8f8f8;
    flex: 1;
    order: 2;
}

.card-contenido h3 {
    color: #ff914d;
    margin: 5px 0;
}

.card-contenido p {
    color: #333;
    font-size: 14px;
    line-height: 1.5;
    text-align: justify;
    font-size: 16px;
}

.card-imagen {
    width: 200px;
    height: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.card-imagen img {
    border-radius: 50%;
    width: 170px;
    height: 170px;
    object-fit: cover;
    margin-bottom: 10px;
}

.card-imagen h4 {
    color: #38b6ff;
    margin: 0;
    font-size: 1rem;
    text-align: center;
    position: static;
}

.card-conocenos.right-image .card-imagen {
    order: 2;
}

.card-conocenos.right-image .card-contenido {
    order: 1;
}

@media (max-width: 768px) {

    .conocenos-container,
    .conocenos-header {
        width: calc(100% - 20px);
        margin: 0 auto;
        padding: 0;
    }

    .card-conocenos {
        flex-direction: column;
        text-align: center;
        margin: 0 auto;
        width: calc(100% - 20px);
        padding: 0;
        box-sizing: border-box;
    }

    .card-conocenos.right-image .card-imagen,
    .card-conocenos.right-image .card-contenido {
        order: unset;
    }

    .card-imagen {
        width: 100%;
        height: auto;
    }

    .card-imagen h4 {
        margin-bottom: 10px;
    }

    .conocenos-header h3 {
        font-size: 1rem;
        width: 90%;
    }
}