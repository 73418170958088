.legal-container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    text-align: justify;
}

.legal-container h1 {
    margin-bottom: 20px;
    text-align: center;
}

.legal-container h2 {
    margin-top: 30px;
    color: #333;
}

.legal-container p {
    line-height: 1.6;
    margin-bottom: 15px;
}
