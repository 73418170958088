/*HEADER PLANES*/
.planes-header {
  text-align: center;
  margin: 40px 20px;
}

.planes-header h3 {
  text-align: center;
  font-size: 2rem;
  color: white;
  margin-bottom: 10px;
  border-radius: 30px;
  background-color: #38b6ff94;
  padding: 15px;
  width: 50%;
  margin: 0 auto;
  margin-bottom: 15px;
}

.planes-header p {
  font-size: 1.125rem;
  color: #555;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
}

/*CARDS*/
.planes {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 20px;
  top: 15px;
}

.card {
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: max-height 0.5s ease-in-out;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  max-height: 800px;
  height: 100%;
}

.card.expanded {
  max-height: 1400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.card-form {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  margin-top: 20px;
  margin-bottom: 25px;
}
.btnEnviar{
  margin-top: 40px !important;
}


.card.expanded .card-form {
  opacity: 1;
}

.card h3 {
  margin-top: 0;
  font-size: 1.25rem;
}

.card-description{
  margin-bottom: 20px;
}
.card-description p {
  text-align: center;
}

.precio-card {
  font-size: 16px;
  color: #939697;
  text-align: left;
  position: absolute;
  bottom: 25px;
  transition: bottom 0.5s ease-in-out;
}
.card.expanded .precio-card {
  bottom: 80px;
}

.card button {
  background-color: #38b6ff;
  border: none;
  color: white;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  margin-top: auto;
  width: 100px;
  margin-left: auto;
  margin-right: auto;
}

.card button:hover {
  background-color: #ff914d;
}

@media (max-width: 938px) {
  .planes {
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  }

  .planes-header h3 {
    font-size: 1rem;
    width: 90%;
  }
}

@media (max-width: 768px) {
  .planes {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .card {
    padding: 15px;
  }

  .card button {
    padding: 8px 12px;
    font-size: 16px;
  }

  .card-description p {
    font-size: 0.875rem;
  }

  .planes-header h3 {
    font-size: 1rem;
    width: 90%;
  }
}